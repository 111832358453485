import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "../img/icons/expand-less.svg";
import ExpandMore from "../img/icons/expand-more.svg";

export default function Question(props) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className="question-list-container"
    >
      <ListItem className="question-container" onClick={handleClick}>
        <ListItemText primary={props.question} className="question" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className="answer">
            <ListItemText primary={props.answer} />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}
