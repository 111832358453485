import React from "react";
import { navigate } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    console.log(this.state);
    console.log(e.target.name);
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <form
        name="questions"
        method="post"
        action="/contact/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>
        <div className="field">
          <label style={{ color: "white" }} className="label" htmlFor={"name"}>
            Nafn
          </label>
          <div className="control">
            <input
              className="input"
              type={"text"}
              name={"name"}
              onChange={this.handleChange}
              id={"name"}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label style={{ color: "white" }} className="label" htmlFor={"email"}>
            Netfang
          </label>
          <div className="control">
            <input
              className="input"
              type={"email"}
              name={"email"}
              onChange={this.handleChange}
              id={"email"}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label
            style={{ color: "white" }}
            className="label"
            htmlFor={"question"}
          >
            Spurningin þín
          </label>
          <div className="control">
            <input
              className="input"
              name={"question"}
              type={"text"}
              onChange={this.handleChange}
              id={"question"}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <button className="button is-link login-btn" type="submit">
            Senda
          </button>
        </div>
      </form>
    );
  }
}
