import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/Layout";
import Question from "../components/Question";
import QuestionForm from "../components/QuestionForm";
import BreadCrumb from "../components/BreadCrumb";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export const ProductPageTemplate = ({ faqlist, classes }) => (
  <div className="content">
    <div className="container" style={{ marginBottom: "-1.5rem" }}>
      {faqlist && (
        <Grid justify="space-between" container className="questions-container">
          <Grid className={classes.root} item md={6} xs={12}>
            <div className="question-list">
              <BreadCrumb page="Spurt og Svarað"></BreadCrumb>
              <h2>Spurt og svarað</h2>
              {faqlist.map((faqlistitem) => (
                <Question
                  question={faqlistitem.question}
                  answer={faqlistitem.answer}
                ></Question>
              ))}
            </div>
          </Grid>

          <Grid item md={6} xs={12}>
            <div className="question-form">
              <h2>Fannstu ekki svar við spurningunni þinni?</h2>
              <p>Sendu okkur spurningu og við svörum við fyrsta tækifæri.</p>
              <QuestionForm></QuestionForm>
              <p className="underline-text">
                Hér svörum við aðeins almennum spurningum um gróðurhúsin og
                umgjörðina sem Samfélagsgróðurhús býður upp á. Inn á{" "}
                <b>mínum síðum</b> munt þú geta nálgast ítarlega fræðslu.
              </p>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  </div>
);

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const classes = useStyles();

  return (
    <Layout>
      <ProductPageTemplate
        classes={classes}
        title={frontmatter.title}
        heading={frontmatter.heading}
        faqlist={frontmatter.faqlist}
      />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        faqlist {
          question
          answer
        }
      }
    }
  }
`;
